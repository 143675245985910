import isString from 'lodash/isString';
import { useDropzone } from 'react-dropzone';
// @mui
import { FormHelperText, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';

import { useI18n } from '@wi-core/ui';

import Image from '@wi-themes/minimal/src/components/Image';
import Iconify from "@wi-themes/minimal/src/components/Iconify";

import { useFormatNumber } from '../../../../hooks';
import i18n from '../../i18n';

import UploadAvatarProps from './types/UploadAvatarProps';


// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    width: 144,
    height: 144,
    margin: 'auto',
    borderRadius: '50%',
    padding: theme.spacing(1),
    border: `1px dashed ${theme.palette.grey[500_32]}`,
}));

const DropZoneStyle = styled('div')({
    zIndex: 0,
    width: '100%',
    height: '100%',
    outline: 'none',
    display: 'flex',
    overflow: 'hidden',
    borderRadius: '50%',
    position: 'relative',
    alignItems: 'center',
    justifyContent: 'center',
    '& > *': { width: '100%', height: '100%' },
    '&:hover': {
        cursor: 'pointer',
        '& .placeholder': {
            zIndex: 9,
        },
    },
});

const PlaceholderStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    position: 'absolute',
    alignItems: 'center',
    flexDirection: 'column',
    justifyContent: 'center',
    color: theme.palette.text.secondary,
    backgroundColor: theme.palette.background.neutral,
    transition: theme.transitions.create('opacity', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.shorter,
    }),
    '&:hover': { opacity: 0.72 },
}));

// ----------------------------------------------------------------------

const UploadAvatar: React.FC<UploadAvatarProps> = ({
    error,
    file,
    helperText,
    sx,
    ...other
}) => {

    const { translate, lang } = useI18n();
    const { fData } = useFormatNumber();

    const {
        getRootProps,
        getInputProps,
        isDragActive,
        isDragReject,
        fileRejections
    } = useDropzone({
        multiple: false,
        ...other,
    });

    const translateFileRejections = (code: string, message: string) => {
        if (code === 'file-too-large' && other.maxSize) return `${i18n[lang.language].rejection_files.file_too_large} ${fData(other.maxSize)}`;

        if (code === 'file-invalid-type' && other.accept) return `${i18n[lang.language].rejection_files.file_type_must_be} ${other.accept.toString().replaceAll("image/", "*.")}`;

        return message;
    }

    return (
        <>
            <RootStyle
                sx={{
                    ...((isDragReject || error || fileRejections.length > 0) && {
                        borderColor: 'error.light',
                    }),
                    ...sx,
                }}
            >
                <DropZoneStyle
                    {...getRootProps()}
                    sx={{
                        ...(isDragActive && { opacity: 0.72 }),
                    }}
                >
                    <input {...getInputProps()} />

                    {file && (
                        <Image alt="avatar" src={isString(file) ? file : file.preview} sx={{ zIndex: 8 }} />
                    )}

                    <PlaceholderStyle
                        className="placeholder"
                        sx={{
                            ...(file && {
                                opacity: 0,
                                color: 'common.white',
                                bgcolor: 'grey.900',
                                '&:hover': { opacity: 0.72 },
                            }),
                            ...((isDragReject || error || fileRejections.length > 0) && {
                                bgcolor: 'error.lighter',
                            }),
                        }}
                    >
                        <Iconify icon={'ic:round-add-a-photo'} sx={{ width: 24, height: 24, mb: 1 }} />
                        <Typography variant="caption">
                            {file ? i18n[lang.language].upload_avatar.message_update_image : i18n[lang.language].upload_avatar.message_upload_image}
                        </Typography>
                    </PlaceholderStyle>
                </DropZoneStyle>
            </RootStyle>

            {helperText && helperText}

            <FormHelperText error sx={{ m: '8px 8px 0 8px', minHeight: '8px' }}>
                {error && translate(error || '')}
                {!error && fileRejections.length > 0 && translateFileRejections(fileRejections[0].errors[0].code, fileRejections[0].errors[0].message)}
            </FormHelperText>
        </>
    );
}

export default UploadAvatar;