import { WebStorageStateStore } from 'oidc-client-ts';

import EnvironmentConfig from './types/EnvironmentConfig';
import EnvironmentType from './types/EnvironmentType';

const Environment: EnvironmentConfig = {
    type: EnvironmentType.Production,
    oidcConfig: {
        authority: 'https://wecare.ioc.med.br/auth/realms/weinnovate',
        client_id: 'wi-frontend',
        redirect_uri: 'https://wecare.ioc.med.br/portal',
        post_logout_redirect_uri: 'https://wecare.ioc.med.br/login',
        userStore: new WebStorageStateStore({ store: localStorage }),
        onSigninCallback: (): void => {
            window.history.replaceState({}, document.title, window.location.pathname);
        }
    },
    loginPath: '/login',
    portalPath: '/portal',
    endpoint: 'https://wecare.ioc.med.br',
    fullCalendarLicenseKey: '0204466063-fcs-1678379458',
    version: '10.14.0'
};

export default Environment;
