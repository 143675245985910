import { RegisterType } from "@wi-core/shared-blocks";
import PersonManagerFormType from "../@types/PersonManagerFormType";

const InitialValues: PersonManagerFormType = {
    license: null,
    firstName: '',
    lastName: '',
    image: {
        name: '',
        mimeType: '',
        content: '',
        deleteImage: false
    },
    imageId: null,
    gender: '',
    birthDate: null,
    motherName: '',
    nationality: null,
    profession: null,
    email: '',
    address: {
        zipCode: '',
        publicPlace: '',
        number: null,
        district: '',
        country: null,
        state: null,
        city: null,
        complement: ''
    },
    contacts: [],
    documents: [],
    healthCards: [],
    dependents: [],
    integrationLinks: [],
    externalUsers: [],
    verified: 'Yes',
    hasNoEmail: false,
    registerType: RegisterType.Main
}

export default InitialValues;
